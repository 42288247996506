<template>
  <div class="edit-banner_campaign">
    <b-card>
      <div class="heading">
        <p>
          Edit Campaign
        </p>
      </div>
      <!-- form -->
      <validation-observer ref="editCampaignForm">
        <b-form
          class="mt-2"
          style="
          width: 100%"
          @submit.prevent
        >
          <b-col
            md="6"
            class="mx-auto"
          >
            <b-form-group
              text-bolder
              label="Campaign Name"
              label-for="blog-edit-title"
              class="mb-2 campaign-label"
            >
              <validation-provider
                v-slot="{ errors }"
                name="campaign name"
                rules="required"
              >
                <b-skeleton
                  v-if="isCampaign"
                  type="input"
                />
                <b-form-input
                  v-else
                  id="blog-edit-title"
                  v-model="blogData.name"
                  placeholder="Please enter Campaign name here"
                  style="height: 42px"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- devices select-->
          <b-col
            md="6"
            class="mx-auto"
          >
            <b-form-group
              label="Screens"
              label-for="blog-edit-category"
              class="mb-2 campaign-label"
            >
              <b-skeleton
                v-if="isCampaign"
                type="input"
              />
              <v-select
                v-else
                v-model="userSelectedDevices"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                label="name"
                :options="filteredDevices"
                class="search"
                @input="selectDevicesFunc(userSelectedDevices)"
                @search="onSearch"
              />
            </b-form-group>
          </b-col>
          <b-col />

        </b-form>
      </validation-observer>
      <b-row
        md="12"
        lg="12"
        class="mb-2 px-1"
      >
        <b-col
          md="4"
          lg="4"
        >
          <b-form-group
            label="Select Starting Date"
            label-for="blog-edit-category"
            class="mb-2 campaign-label"
          >
            <b-form-datepicker
              v-model="blogData.start_date"
              :state="blogData.start_date ? true : null"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="4"
          lg="4"
        >
          <b-form-group
            label="Select Ending Date"
            label-for="blog-edit-category"
            class="campaign-label"
          >
            <b-form-datepicker
              v-model="blogData.end_date"
              :min="new Date(blogData.start_date)"
              :disabled="!blogData.start_date"
              :state="blogData.start_date && blogData.end_date ? new Date(blogData.start_date) > new Date(blogData.end_date) ? false : true : null"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="4"
          lg="4"
        >
          <b-form-group
            label="Select Time Zone"
            label-for="blog-edit-category"
            class="campaign-label"
          >
            <v-select
              v-model="blogData.timezone"
              class="zindex-2"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              select-size="3"
              :clearable="false"
              :searchable="true"
              :options="timezones"
              placeholder="Select Time Zone"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <Calendar
        ref="calendar"
        v-model="date"
        :attributes="attributes"
        is-expanded
        @dayclick="onDayClick"
      />
      <div
        class="d-flex justify-content-center"
      >
        <b-button
          variant="outline-primary"
          class="my-1 mx-1"
          :disabled="disableSetTime"
          @click="setTimeForSelectedDates"
        >
          Set Time
        </b-button>
        <b-button
          v-if="ifTimeSlotsExists"
          variant="outline-primary"
          class="my-1 mx-1"
          @click="editTimeForSelectedDates"
        >
          View Schedule
        </b-button>
      </div>
      <!--/ form -->
      <BannerAdsMedia
        :max-time-limit="TimeLimitCondition.maxBannerAdsMediaCondition"
        @addMediaToCampaign="addMediaToCampaignFile"
      />
      <AddTimeSlotsInternalAdCampaigns
        ref="add-time-slots"
        :dates-for-time-slots="selectedDates"
        :array-of-dates-and-time-slots="finalDatesWithTimeSlots"
        @addDatesWithTimeSlotsToArray="addDatesWithTimeSlotsToArray"
        @notSetTimeSlot="notSetTimeSlot"
      />
      <viewListDatesWithTimeSlots
        ref="view-time-slots"
        :array-of-dates-and-time-slots="finalDatesWithTimeSlots"
        @addDatesWithTimeSlotsToArrayAfterViewingList="addDatesWithTimeSlotsToArrayAfterViewingList"
      />
      <AddedMediaToCampaign
        ref="add-media-cam-ref"
        :added-media="addedFilesToCampaign"
        :max-time-limit="TimeLimitCondition.maxBannerAdsMediaCondition"
        :is-draggable="true"
        @removeMediaFromList="deleteMediaFromCampaign"
        @changedOrderOfList="changedOrderOfList"
      />
      <div class="d-flex justify-content-center mt-0 ">
        <b-button
          variant="outline-primary"
          class="my-1"
          @click="reviewCampaign"
        >
          Review
        </b-button>
        <b-button
          variant="primary"
          class="m-1"
          :disabled="spinner"
          @click="editCampaign"
        >
          <div
            v-if="spinner"
            class="spinner"
          >
            <b-spinner
              small
            />
          </div>
          Save
        </b-button>
        <b-button
          class="choose my-1"
          @click="cancelCampaign"
        >
          Cancel
        </b-button>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BForm, BCol, BFormGroup, BFormInput, BButton, VBModal,
  // BLink,
  BSkeleton, BSpinner, BFormDatepicker,
  BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { Calendar } from 'v-calendar'
import MediaType from '@/common/enums/mediaTypeEnum'
import BannerAdsMedia from '@/components/common/BannerAdsMedia.vue'
import AddedMediaToCampaign from '@/components/common/AddedMediaToCampaign.vue'
import { getThumbnailOfFile, showErrorMessage, showToast } from '@/common/global/functions'
import TimeLimitCondition from '@/common/config'
import store from '@/store'
import AccountTypes from '@/common/enums/accountTypeEnum'
import MemberPermissions from '@/common/enums/memberPermissionsEnum'
import AddTimeSlotsInternalAdCampaigns from '@/components/bannerads/AddTimeSlotsInernalAdCampains.vue'
import viewListDatesWithTimeSlots from '@/components/bannerads/viewListDatesWithTimeSlots.vue'
import {
  convertToSchedulerFormatToUTCForInternal,
  getAllTimezones,
  convertFromUTCToSchedulerTimeZoneFormatForInternal,
  convertedDataComingFromSchedulerApi,
  concatenateDateWithTimeSlotsForInternal,
} from '@/common/global/calenderFunctions'

export default {
  components: {
    BCard,
    BForm,
    BCol,
    BRow,
    AddedMediaToCampaign,
    BFormGroup,
    BFormInput,
    vSelect,
    BButton,
    // BLink,
    BannerAdsMedia,
    BSkeleton,
    BSpinner,
    // validations
    ValidationProvider,
    ValidationObserver,
    Calendar,
    AddTimeSlotsInternalAdCampaigns,
    viewListDatesWithTimeSlots,
    BFormDatepicker,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  beforeRouteEnter(to, from, next) {
    const memberPermission = store.getters['user/getPermissionOfMember'](MemberPermissions.INTERNAL_NETWORK_ADS)
    const adminRole = store.getters['user/getUserRole']
    if (memberPermission || (adminRole === AccountTypes.ADMIN)) {
      next()
    } else {
      next({
        name: 'dashboard',
      })
    }
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['id'],
  data() {
    return {
      TimeLimitCondition,
      MediaType,
      AccountTypes,
      isCampaign: false,
      spinner: false,
      search: '',
      addedFilesToCampaign: [],
      userSelectedDevices: [],
      userSelectedGroups: [],
      campaign: '',
      media: [],
      allDevices: [],
      allGroups: [],
      blogData: {
        name: '',
        bannerCampaignId: Number(this.$route.params.id),
        banner_has_group: [],
        banner_has_device: [],
        banner_has_media: [],
        banner_has_schedule: [],
        start_date: null,
        end_date: null,
        timezone: null,
      },
      // validation
      required,
      date: new Date(),
      ifTimeSlotsExists: false,
      disableSetTime: true,
      days: [],
      finalDatesWithTimeSlots: [],
      arrayOfDatesCollected: [],
      selectedDates: [],
      convertedDatesAndslots: [],
      timezones: [],
    }
  },
  computed: {
    filteredDevices() {
      return this.allDevices.filter(device => !this.userSelectedDevices.map(res => res.id).includes(device.id))
    },
    userRole() {
      return this.$store.getters['user/getUserRole']
    },
    dates() {
      return this.days.map(day => day.date)
    },
    attributes() {
      const currentAttributes = this.dates.map(date => ({
        highlight: true,
        dates: date,
      }))
      const previousAttributes = this.arrayOfDatesCollected.map(id => ({
        bar: 'red',
        dates: id,
      }))

      return [...currentAttributes, ...previousAttributes]
    },
  },
  async mounted() {
    await this.getAllDevices()
    this.timezones = await getAllTimezones()
    await this.getBannerCampaign(this.$route.params.id)
    if (this.campaign.banner_campaign_scheduler.length) {
      this.mapDataToCalendar()
    }
  },
  methods: {
    getThumbnailOfFile(file) {
      return getThumbnailOfFile(file)
    },
    async editCampaign() {
      this.blogData.banner_has_schedule = await concatenateDateWithTimeSlotsForInternal(this.finalDatesWithTimeSlots)
      this.blogData.banner_has_schedule = await convertToSchedulerFormatToUTCForInternal(this.blogData.timezone, this.blogData.banner_has_schedule)
      this.$refs.editCampaignForm.validate().then(async success => {
        if (success) {
          if (!this.blogData.banner_has_device.length && !this.blogData.banner_has_group.length) {
            showToast('Edit Internal Network Ad', 'Please add devices or devices group to update internal network ad!', 'danger', 4000)
          } else if (!this.blogData.banner_has_media.length) {
            showToast('Edit Internal Network Ad', 'Please add media to update internal network ad!', 'danger', 4000)
          } else if (!this.blogData.start_date) {
            showToast('Add Internal Network Ad', 'Please add Starting date to create internal network ad!', 'danger', 4000)
          } else if (!this.blogData.end_date) {
            showToast('Add Internal Network Ad', 'Please add Ending date to create internal network ad!', 'danger', 4000)
          } else if (!this.validateDates()) {
            showToast('Add Internal Network Ad', 'Ending date should be ahead of Starting date!', 'danger', 4000)
          } else {
            this.spinner = true
            await this.editBannerCampaign()
            this.spinner = false
          }
        }
      })
    },
    editBannerCampaign() {
      if (this.userRole === this.AccountTypes.USER) {
        return this.$axios
          .put('banner-campaigns', this.blogData)
          .then(() => {
            showToast('Edit Internal Network Ad', 'Internal Network Ad has been edited successfully!')
            this.$router.push('/banner-ads')
          }).catch(({
            response: {
              data: {
                statusCode, message,
              },
            },
          }) => {
            if (showErrorMessage(statusCode)) {
              showToast('Edit Internal Network Ad', message.toString(), 'danger')
            }
          })
      }
      return this.$axios
        .put('admin/banner-campaign', this.blogData, {
          headers: {
            userHasGroupId: this.$route.params.userId,
          },
        })
        .then(() => {
          showToast('Update Internal Network Ad', 'Internal Network Ad has been edited successfully!')
          this.$router.push(`/users/${this.$route.params.userId}/user-banner-ads`)
        }).catch(({
          response: {
            data: {
              statusCode, message,
            },
          },
        }) => {
          if (showErrorMessage(statusCode)) {
            showToast('Update Internal Network Ad', message.toString(), 'danger')
          }
        })
    },
    async  getAllDevices() {
      if (this.userRole === AccountTypes.USER) {
        await this.$axios.get(`user/device?offset=${0}&limit=${20}&search_query=${this.search}`)
          .then(({
            data: {
              data,
            },
          }) => {
            this.allDevices = data.results
          }).catch(({
            response: {
              data: {
                statusCode, message,
              },
            },
          }) => {
            if (showErrorMessage(statusCode)) {
              showToast('Edit Internal Network Ad', message.toString(), 'danger')
            }
          })
      } else {
        try {
          const { data: { data } } = await this.$axios.get(`admin/device/${this.$route.params.userId}?offset=${0}&limit=${20}&search_query=${this.search}`)
          this.allDevices = data.results
        } catch ({
          response: {
            data: {
              statusCode, message,
            },
          },
        }) {
          if (showErrorMessage(statusCode)) {
            showToast('Edit Internal Network Ad', message.toString(), 'danger')
          }
        }
      }
    },

    async  getBannerCampaign(id) {
      this.isCampaign = true
      const payload = {
        userHasGroupId: this.$route.params.userId,
        bannerId: id,
      }
      if (this.userRole === this.AccountTypes.USER) {
        await this.$axios
          .get(`banner-campaigns/${id}`)
          .then(({ data }) => {
          // eslint-disable-next-line prefer-destructuring
            this.campaign = data.data
            this.blogData.name = this.campaign.name
            this.updateIdsOfDevicesInPayload(this.campaign)
            this.updateIdsOfDevicesGroupInPayload(this.campaign)
            this.blogData.timezone = this.campaign.timezone
            this.addedFilesToCampaign = this.campaign.banner_campaign_has_media.map(res => res.media)
            this.updateIdsOfMediaInPayload()
            this.isCampaign = false
          }).catch(({
            response: {
              data: {
                statusCode, message,
              },
            },
          }) => {
            if (showErrorMessage(statusCode)) {
              showToast('Edit Internal Network Ad', message.toString(), 'danger')
            }
          })
      } else {
        try {
          const res = await this.$store.dispatch('bannerAds/getSingleBannerAd', { ...payload })
          if (res.data.statusCode === 200) {
            this.campaign = res.data.data
            this.blogData.name = this.campaign.name
            this.updateIdsOfDevicesInPayload(this.campaign)
            this.updateIdsOfDevicesGroupInPayload(this.campaign)
            this.blogData.timezone = this.campaign.timezone
            this.addedFilesToCampaign = this.campaign.banner_campaign_has_media.map(response => response.media)
            this.updateIdsOfMediaInPayload()
            this.isCampaign = false
          }
        } catch ({
          response: {
            data: {
              statusCode, message,
            },
          },
        }) {
          if (showErrorMessage(statusCode)) {
            showToast('Edit Internal Network Ad', message.toString(), 'danger')
          }
        }
      }
    },
    updateIdsOfDevicesInPayload(campaign) {
      this.userSelectedDevices = campaign.banner_campaigns_has_devices.map(res => res.device)
      this.blogData.banner_has_device = this.userSelectedDevices.map(res => res.id)
    },
    updateIdsOfDevicesGroupInPayload(campaign) {
      this.userSelectedGroups = campaign.banner_campaign_has_group.map(res => res.device_groups)
      this.blogData.banner_has_group = this.userSelectedGroups.map(res => res.id)
    },
    updateIdsOfMediaInPayload() {
      this.blogData.banner_has_media = this.addedFilesToCampaign.map(res => res.id)
    },
    async onSearch(query, loading) {
      this.search = query
      if (this.search.length) {
        loading(true)
        await this.getAllDevices()
        loading(false)
      } else {
        loading(false)
      }
    },
    addMediaToCampaignFile(object) {
      this.addedFilesToCampaign.push(object)
      this.updateIdsOfMediaInPayload()
    },
    selectDevicesFunc(userSelectedDevices) {
      this.blogData.banner_has_device = userSelectedDevices.map(res => res.id)
    },
    selectGroupFunc(userSelectedGroups) {
      this.blogData.banner_has_group = userSelectedGroups.map(res => res.id)
    },
    deleteMediaFromCampaign(index) {
      this.addedFilesToCampaign.splice(index, 1)
      this.updateIdsOfMediaInPayload()
    },
    changedOrderOfList(array) {
      this.addedFilesToCampaign = array
      this.updateIdsOfMediaInPayload()
    },
    cancelCampaign() {
      if (this.userRole === this.AccountTypes.ADMIN) {
        this.$router.push(`/users/${this.$route.params.userId}/user-banner-ads`)
      } else {
        this.$router.push('/banner-ads')
      }
    },
    async reviewCampaign() {
      if (this.addedFilesToCampaign) {
        await this.$store.dispatch('lightBox/lightBoxData', this.addedFilesToCampaign)
      }
    },
    async onDayClick(day) {
      const idx = this.days.findIndex(d => d.id === day.id)
      if (idx >= 0) {
        this.days.splice(idx, 1)
        if (this.days.length === 0) {
          this.disableSetTime = true
          this.setAllDatesAvailable()
        }
      } else {
        this.disableSetTime = false
        this.days.push({
          id: day.id,
          date: day.date,
        })
      }
    },
    setTimeForSelectedDates() {
      this.days.forEach(element => this.selectedDates.push(element.id))
      this.$refs['add-time-slots'].show()
    },
    async editTimeForSelectedDates() {
      this.setAllDatesAvailable()
      this.disableSetTime = true
      this.$refs['view-time-slots'].show()
    },
    addDatesWithTimeSlotsToArray(array) {
      this.days.forEach(element => this.arrayOfDatesCollected.push(element.id))
      this.days = []
      this.selectedDates = []
      this.disableSetTime = true
      this.setAllDatesAvailable()
      this.finalDatesWithTimeSlots = array
      if (this.finalDatesWithTimeSlots.length > 0) {
        this.ifTimeSlotsExists = true
      }
    },
    notSetTimeSlot() {
      this.setAllDatesAvailable()
      this.disableSetTime = true
    },
    setAllDatesAvailable() {
      this.days = []
      this.selectedDates = []
    },
    async addDatesWithTimeSlotsToArrayAfterViewingList(array) {
      this.arrayOfDatesCollected = []
      this.finalDatesWithTimeSlots = array
      await this.finalDatesWithTimeSlots.forEach(element => this.arrayOfDatesCollected.push(element.key))
      if (this.finalDatesWithTimeSlots.length === 0) {
        this.ifTimeSlotsExists = false
      }
    },
    validateDates() {
      if (this.blogData.start_date && this.blogData.end_date && new Date(this.blogData.start_date) <= new Date(this.blogData.end_date)) {
        return true
      }
      return false
    },
    async mapDataToCalendar() {
      this.campaign.banner_campaign_scheduler = await convertFromUTCToSchedulerTimeZoneFormatForInternal(this.campaign.timezone, this.campaign.banner_campaign_scheduler)
      this.campaign.banner_campaign_scheduler.forEach(slots => {
        this.arrayOfDatesCollected.push(slots.start_date_time.split('T')[0])
      })
      this.blogData.start_date = this.campaign.start_date
      this.blogData.end_date = this.campaign.end_date
      const leastDate = this.arrayOfDatesCollected
        .map(date => new Date(date))
        .sort((a, b) => a - b)[0]
      this.$refs.calendar.move({ month: leastDate.getMonth() + 1, year: leastDate.getFullYear() })
      this.finalDatesWithTimeSlots = await convertedDataComingFromSchedulerApi(this.campaign.banner_campaign_scheduler)
      this.ifTimeSlotsExists = true
    },
  },
}
</script>
<style src="vue-it-bigger/dist/vue-it-bigger.min.css"></style>
<style lang="scss">
.edit-banner_campaign {
  .search {
    height: 42px !important;
    .vs__search, .vs__search:focus{
      height:36px !important;
    }
    .vs__dropdown-menu {
      max-height: 80px !important;
      overflow-y: auto !important;
    }
    .vs__dropdown-toggle{
      max-height: 70px !important;
      overflow-y: auto !important;
    }
    .vs__search, .vs__search:focus{
      font-size: medium !important;
    }
  }
  .campaign-label{
    font-size: 13px;
    line-height: 18px;
    color: #323232;
    font-weight: 600;
    font-family: "Montserrat";
  }
}
.heading{
  font-size: 20px;
  line-height: 44px;
  color: #1f58b5;
  font-weight: 600;
  font-family: "Montserrat";
  text-align: center;
}
.mediaText{
  font-size: 14px;
  line-height: 22px;
  color: #323232;
  font-weight: 600;
  font-family: "Montserrat";
  display: flex;
  justify-content: center;
  margin-top: 2rem;

}
.addDevice{
  width: 171px;
  height: 65px;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 25px;
}
.text{
  font-size: 12px;
  color: #5e5873;
  font-weight: 500;
  font-family: "Montserrat";
}
.ml-0{
  font-size: 10px;
  color: #6e6b7b;
  font-weight: 400;
  font-family: "Montserrat";
}
.choose{
  display: flex;
  background-color: #e0dee1 !important;
  color: #6e6b7b !important;
  border-color: #e0dee1 !important;
}
.modal1{
  padding: 0 !important;
}
.vc-bars{
  width: 20% !important;
}
.vc-day-content:focus {
  font-weight: normal !important;
}
</style>
